<template>
  <button class="close-button" @click="closeCart">×</button>
  <div class="shopping-cart">
    <template v-if="!showForm && !orderSubmitted">
      <div class="cart-header">
        <h2>{{ $t('main-page.cart.title') }}</h2>
        <!-- <button class="clear-button" @click="clearCart">Очистити</button> -->
      </div>
      <div class="cart-item" v-for="item in localCartItems" :key="item.article">
        <img :src="item.photo" alt="Image" />
        <div class="item-details">
          <p class="item-name">{{ item.name }}</p>
          <p class="item-code">{{ item.article }}</p>
          <p class="item-price">{{ item.price * item.quantity }} {{ $t('main-page.cart.currency') }}</p>
        </div>
        <div class="mock-quantity"></div>
        <div class="item-quantity">
            <button @click="updateQuantity(item, -1)">-</button>
            <span>{{ item.quantity }}</span>
            <button @click="updateQuantity(item, 1)" class="btn">+</button>
        </div>
        <button class="remove-button" @click="updateQuantity(item, -100)">×</button>
      </div>
      <div class="cart-summary">
        <div class="summary-text">
          <!-- <p>Загальна вага: {{ totalWeight }} грам</p> -->
          <p class="total-label">{{ $t('main-page.cart.total') }}:</p>
          <p class="total-price">{{ totalPrice }} {{ $t('main-page.cart.currency') }}</p>
        </div>
        <button :class="{ 'disabled': localCartItems.length === 0 }" class="checkout-button" @click="showForm = true"
          :disabled="localCartItems.length === 0">{{ $t('main-page.cart.order') }}
          <span class="arrow">></span>
        </button>
      </div>
    </template>
    <template v-else-if="showForm && !orderSubmitted">
      <h2>{{ $t('main-page.checkout.title') }}</h2>
      <form @submit.prevent="submitForm">
        <div class="form-section">
          <h3>{{ $t('main-page.checkout.personal-info') }}</h3>
          <div class="form-row">
            <div class="form-group-n name-group">
              <label for="name">{{ $t('main-page.checkout.name') }}<span class="required">*</span></label>
              <input type="text" v-model="name" id="name" :placeholder="$t('main-page.checkout.name')" maxlength="25" :class="{ 'input-error': !nameValid }" required />
            </div>
            <div class="form-group-n phone-group">
              <label for="phone">{{ $t('main-page.checkout.phone') }}<span class="required">*</span></label>
              <input type="text" v-model="phone" id="phone" :placeholder=" $t('main-page.checkout.name')" maxlength="13" :class="{ 'input-error': !phoneValid }" required @input="formatPhoneNumber" @blur="validatePhoneNumber"/>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group-n name-group">
              <label for="email">{{ $t('main-page.checkout.email') }}<span class="required"></span></label>
              <input type="email" v-model="email" id="email" placeholder="Email" maxlength="35" :class="{ 'input-error': !nameValid }" />
            </div>
          </div>
        </div>
        <div class="form-section mtt pdb">
          <h3>{{ $t('main-page.checkout.delivery') }}</h3>
          <div class="form-group">
            <button type="button" @click="setDeliveryType('pickup')"
              :class="{ active: deliveryType === 'pickup' }">{{ $t('main-page.checkout.pickup') }}</button>
            <button type="button" @click="setDeliveryType('delivery')"
              :class="{ active: deliveryType === 'delivery' }">{{ $t('main-page.checkout.delivery') }}</button>
          </div>

          <!-- <div v-if="deliveryType === 'pickup'" class="pickup-address">
            {{ $t('main-page.checkout.pickup-address') }}
            <a href="https://maps.app.goo.gl/ZL3VHh7NsPYLDGrh9"> {{ $t('main-page.checkout.check-link') }}</a> 
          </div> -->

          <div v-if="deliveryType === 'pickup'" class="pickup-address">
            <div v-if="multipleActiveRestaurants.length <= 1">
              <span v-if="pickupRestaurant">{{ $t('main-page.checkout.pickup-address-short') }} {{ pickupRestaurant }}</span>
              <span v-else>{{ $t('main-page.checkout.pickup-address') }}</span>
            </div>
            <div v-else class="form-group-n">
              <span>{{ $t('main-page.checkout.pickup-address-short') }} </span>
              <select v-model="selectedRestaurantId" @change="updatePickupRestaurant">
                <option v-for="restaurant in multipleActiveRestaurants" :key="restaurant.id" :value="restaurant.id">
                  {{ restaurant.address }}
                </option>
              </select>
            </div>
          </div>



          <div v-if="deliveryType === 'delivery'" class="form-group-n">
            <div class="form-row">
              <div class="form-group-n name-group">
                <label for="street">{{ $t('main-page.checkout.street') }}<span class="required">*</span></label>
                <input
                  type="text"
                  v-model="street"
                  if="street"
                  placeholder="Введіть назву вулиці"
                  :class="{ 'input-error': !streetValid || streetError }"
                  list="streetOptionsList"
                  required
                />
                <datalist id="streetOptionsList">
                  <option v-for="option in streetOptions" :key="option" :value="option"></option>
                </datalist>
                <div v-if="streetError" class="error-message">
                  {{ $t('main-page.checkout.street-error') }}<a href="#/contact">{{ $t('main-page.checkout.check-link') }}
                  </a>.
                </div>
              </div>
            </div>

            <div class="form-row nogrid">
              <div class="form-group-n delivery-strd-group">
                <label for="house">{{ $t('main-page.checkout.house') }}<span class="required">*</span></label>
                <input type="text" id="house" v-model="house" :placeholder=" $t('main-page.checkout.house') " maxlength="10" :class="{ 'input-error': !houseValid }" required />
              </div>

              <div class="form-group-n delivery-strd-group">
                <label for="entrance">{{ $t('main-page.checkout.entrance') }}</label>
                <input type="text" id="entrance" v-model="entrance" :placeholder="$t('main-page.checkout.entrance')" maxlength="10" />
              </div>

              <div class="form-group-n delivery-strd-group">
                <label for="floor">{{ $t('main-page.checkout.floor') }}</label>
                <input type="text" id="floor" v-model="floor" :placeholder="$t('main-page.checkout.floor')" maxlength="3"/>
              </div>

              <div class="form-group-n delivery-strd-group">
                <label for="apartment">{{ $t('main-page.checkout.apartment') }}</label>
                <input type="text" id="apartment" v-model="apartment" :placeholder=" $t('main-page.checkout.apartment')" maxlength="10"/>
              </div>
            </div>
          </div>
        </div>

        <div class="form-section mtt pdb">
          <h3>{{ $t('main-page.checkout.delivery-time') }}</h3>
          <div class="form-row">
            
              <div class="form-group delivery-time-group">
                <div class="delivery-time-container">
                  <div class="delivery-day">
                    <div class="form-group-n phone-group nmrb">
                      <label for="deliveryDay">{{ $t('main-page.checkout.day') }}<span class="required"></span></label>
                      <select  id="deliveryDay" v-model="deliveryDay" @change="updateTimeOptions">
                        <option value="today">{{ $t('main-page.checkout.today') }}</option>
                        <option value="tomorrow">{{ $t('main-page.checkout.tomorrow') }}</option>
                        <option value="day_after_tomorrow">{{ $t('main-page.checkout.day-after-tomorrow') }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="delivery-time">
                    <div class="form-group-n name-group nmrb">
                      <label for="deliveryTime">{{ $t('main-page.checkout.time') }}<span class="required"></span></label>
                      <select id="deliveryTime" v-model="deliveryTime">
                        <option v-for="time in availableTimes" :key="time" :value="time">{{ time }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            
          </div>
        </div>


        <div class="form-section mtt pdb">
          <h3>{{ $t('main-page.checkout.payment') }}</h3>
          <div class="form-group">
            <button type="button" @click="setPaymentMethod('card')"
            :class="{ active: paymentMethod === 'card' }">{{ $t('main-page.checkout.card') }}</button>
            <button type="button" @click="setPaymentMethod('cash')"
            :class="{ active: paymentMethod === 'cash' }">{{ $t('main-page.checkout.cash') }}</button>
          </div>
        </div>        
        <!-- <div class="form-group">
          <label>Спосіб оплати</label>
          <button type="button" @click="setPaymentMethod('card')"
            :class="{ active: paymentMethod === 'card' }">карта</button>
          <button type="button" @click="setPaymentMethod('cash')"
            :class="{ active: paymentMethod === 'cash' }">готівка</button>
        </div> -->
        <div class="agreement-section">
          <input type="checkbox" id="agreement" v-model="agreementChecked" />
          <label for="agreement">
            {{ $t('main-page.checkout.agreement') }}
            <a href="#/polityka" target="_blank">{{ $t('main-page.checkout.policy-link') }}</a>.
          </label>
        </div>
        <div class="cart-summary wdthau">
          <div class="summary-text">
            <!-- <p>Загальна вага: {{ totalWeight }} грам</p> -->
            <p class="total-label">{{ $t('main-page.cart.total') }}:</p>
            <p class="total-price">{{ totalPrice }} {{ $t('main-page.cart.currency') }}:</p>
          </div>
          <button type="submit" class="checkout-button" :class="{ 'disabled': !isFormValid || isSubmitting }"  :disabled="!isFormValid || isSubmitting">{{ $t('main-page.cart.order') }}:
            <span class="arrow">></span>
          </button>
        </div>
        <!-- <div class="cart-summary">
          <p>Загальна вага: {{ totalWeight }} грам</p>
          <p class="total-price">Разом: {{ totalPrice }} грн</p>
        </div>
        <div class="agreement-section">
          <input type="checkbox" id="agreement" v-model="agreementChecked" />
          <label for="agreement">
            Я погоджуюся з 
            <a href="#/polityka" target="_blank">Договором оферти</a>.
          </label>
        </div> -->
        <!-- <button type="submit" class="checkout-button" :class="{ 'disabled': !isFormValid || isSubmitting }"  :disabled="!isFormValid || isSubmitting">Далі</button> -->
      </form>
    </template>
    <template v-if="orderSubmitted">
      <div class="thank-you-message">
        {{ $t('main-page.checkout.thank-you') }}
      </div>
    </template>
  </div>

  <!-- Error Popup Modal -->
  <div v-if="showErrorPopup" class="popup-overlay-regform" @click="closeErrorPopup">
      <div class="popup-content-regform" @click.stop>
          <h3>{{ $t('main-page.checkout.errorPopupTitle') }}</h3>
          <p>{{ $t('main-page.checkout.errorPopupMessage') }}</p>
          <button @click="closeErrorPopup" class="checkout-button">{{ $t('main-page.checkout.closeButton') }}</button>
      </div>
  </div>

</template>

<script>
import CryptoJS from 'crypto-js';


export default {
  name: 'ShoppingCart',
  props: {
    cartItems: Array
  },
  data() {
    return {
      //localCartItems: [...this.cartItems],
      showForm: false,
      showErrorPopup: false,
      agreementChecked: true, 
      name: '',
      phone: '+38',
      deliveryType: '',
      pickupRestaurant: '',
      email: '',
      street: '',
      isSubmitting: false, 
      house: '',
      entrance: '',
      floor: '',
      apartment: '',
      paymentMethod: '',
      deliveryDay: 'today',
      deliveryTime: 'Найближчий', // Set the default time to "найближчий"
      availableTimes: ["Найближчий"], // Initialize with "найближчий
      streetOptions: [
        'проспект Петра Григоренка', 'вулиця Анни Ахматової', 'вулиця Драгоманова',
        'вулиця Олександра Кошиця', 'вулиця Степана Олійника', 'вулиця Ревуцького',
        'вулиця Бориса Гмирі', 'вулиця Михайла Гришка', 'вулиця Лариси Руденко',
        'вулиця Олександра Мишуги', 'вулиця Соломії Крушельницької', 'проспект Миколи Бажана',
        'Вишняківська вулиця', 'Срібнокільська вулиця', 'вулиця Княжий Затон',
        'Урлівська вулиця', 'Дніпровська набережна', 'вулиця Єлизавети Чавдар',
        'Сортувальна вулиця', 'Центральна вулиця', 'Батуринська вулиця',
        'Здолбунівська вулиця', 'Причальна вулиця', 'вулиця Григорія Ващенка',
        'Позняківська вулиця', '42-га Садова вулиця', 'Зарічна вулиця',
        'Дніпрова вулиця', 'Тальнівська вулиця', 'Заводський провулок',
        'Трускавецька вулиця', 'вулиця Софії Русової', 'вулиця Алімпія Галика',
        'Іжкарська вулиця', 'Осокорський провулок', '49-та Садова вулиця',
        '136-та Садова вулиця', 'Завальна вулиця', '192-га Садова вулиця',
        '138-ма Садова вулиця', '139-та Садова вулиця', '53-тя Садова вулиця',
        '50-та Садова вулиця', 'вул. Осокорська', 'вулиця Маслівка',
        '53-тя А Садова вулиця', '54-та Садова вулиця'
      ],
      filteredStreetOptions: [],
      selectedRestaurantId: '',
      activeRestaurant: null,
      multipleActiveRestaurants: [],
      orderSubmitted: false,
      streetError: false, 
    };
  },
  computed: {
    localCartItems: {
      get() {
        return this.cartItems;
      },
      set(updatedItems) {
        this.$emit('update:cartItems', updatedItems);
      }
    },
    totalWeight() {
      return this.localCartItems.reduce((total, item) => total + item.weight * item.quantity, 0);
    },
    totalPrice() {
      return this.localCartItems.reduce((total, item) => total + item.price * item.quantity, 0);
    },
     isFormValid() {
      return (
        this.nameValid &&
        this.phoneValid &&
        (this.deliveryType === 'pickup' || this.deliveryType === 'delivery') &&
        (this.paymentMethod === 'cash' || this.paymentMethod === 'card') &&
        (this.deliveryType !== 'delivery' ||
          (this.streetValid && this.houseValid && !this.streetError)) &&
        this.agreementChecked
      );
    },


    nameValid() {
      return this.name.length > 0 && this.name.length <= 25;
    },
    phoneValid() {
      const phoneNumber = this.phone.replace(/\D/g, ''); 
      return phoneNumber.length === 12;
    },
    streetValid() {
      try {
        return this.street.length > 0;
      } catch (error) {
          return false; 
      } 
    },
    houseValid() {
      try {
        return this.house.length > 0 && this.house.length <= 10;
      } catch (error) {
          return false; 
      } 
    }
  },
  created(){
    this.loadActiveRestaurant();
    this.loadActiveRestaurantsList();
    this.updateTimeOptions();
    this.loadUserProfile();
    this.updateStreetOptions();
  },
  methods: {
    closeErrorPopup() {
        this.showErrorPopup = false;
    },
    loadActiveRestaurant() {
      const storedRestaurant = localStorage.getItem('activeRestaurant');
      if (storedRestaurant) {
        this.activeRestaurant = JSON.parse(storedRestaurant);
        this.pickupRestaurant = this.activeRestaurant.address;
      }
    },
    loadActiveRestaurantsList() {
      const storedRestaurantDetails = localStorage.getItem('restaurantDetails');
      if (storedRestaurantDetails) {
        const restaurantDetails = JSON.parse(storedRestaurantDetails);
        this.multipleActiveRestaurants = restaurantDetails.filter(restaurant => restaurant.status === 'ACTIVE');

        // Set the selected restaurant to activeRestaurant's id if it exists
        if (this.activeRestaurant && this.multipleActiveRestaurants.length > 1) {
          this.selectedRestaurantId = this.activeRestaurant.id;
        }
      }
      console.log(this.multipleActiveRestaurants.length)
    },
    updatePickupRestaurant() {
      const selected = this.multipleActiveRestaurants.find(restaurant => restaurant.id === this.selectedRestaurantId);
      if (selected) {
        this.pickupRestaurant = selected.name;
        this.activeRestaurant = selected; // Update the activeRestaurant reference
      }
    },
    updateStreetOptions() {
    const storedRestaurantDetails = localStorage.getItem('restaurantDetails');
    if (storedRestaurantDetails) {
      const restaurantDetails = JSON.parse(storedRestaurantDetails);
      const activeRestaurants = restaurantDetails.filter(restaurant => restaurant.status === 'ACTIVE');
      
      // Collect all unique delivery addresses from active restaurants
      const addresses = activeRestaurants.reduce((acc, restaurant) => {
        if (restaurant.deliveryAddress && Array.isArray(restaurant.deliveryAddress)) {
          acc.push(...restaurant.deliveryAddress);
        }
        return acc;
      }, []);

      // Remove duplicate addresses
      this.streetOptions = [...new Set(addresses)];
    }
  },
    loadUserProfile() {
      const encryptedData = localStorage.getItem('userProfile');

      if (encryptedData) {
        // Decrypt the data using AES
        const bytes = CryptoJS.AES.decrypt(encryptedData, 'sushitokash');
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        const userProfile = JSON.parse(decryptedData);
        
        // Load the profile back into your variables
        try {
          this.name = userProfile.name;
          this.phone = userProfile.phone;
          this.email = userProfile.email;
          this.street = userProfile.address.street;
          this.house = userProfile.address.house;
          this.building = userProfile.address.building;
          this.floor = userProfile.address.floor;
          this.apartment = userProfile.address.apartment;
        } catch (error) {
            console.error('Error submitting order:', error);
        } 
      }
    },

    updateTimeOptions() {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinutes = now.getMinutes();
      const currentTime = currentHour * 60 + currentMinutes;

      const startHour = (this.deliveryDay === 'today') ? Math.ceil((currentTime + 60) / 15) * 15 / 60 : 11.5;
      const endHour = 21.5;

      this.availableTimes = ["Найближчий"];

      for (let time = startHour; time <= endHour; time += 0.25) {
        const hours = Math.floor(time);
        const minutes = (time % 1) * 60;
        const timeString = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        this.availableTimes.push(timeString);
      }
    },
    setDeliveryType(type) {
      this.deliveryType = type;
      this.updateTimeOptions();
    },
    setPaymentMethod(method) {
      this.paymentMethod = method;
    },
    selectStreet(option) {
      this.street = option;
      this.filteredStreetOptions = [];
    },
    filterStreetOptions() {
      if (this.street) {
        this.filteredStreetOptions = this.streetOptions.filter(option =>
          option.toLowerCase().includes(this.street.toLowerCase())
        );
      } else {
        this.filteredStreetOptions = [];
      }
    },
    updateQuantity(item, amount) {
    const index = this.localCartItems.findIndex(cartItem => cartItem.article === item.article);
    console.log("updateQuantity");
    console.log(this.localCartItems);
    if (index !== -1) {
      const newQuantity = this.localCartItems[index].quantity + amount;
      if (newQuantity > 0) {
        console.log("updateQuantity newQuantity > 0 index" + index);
        this.localCartItems[index].quantity = newQuantity;
      } else {
        console.log("updateQuantity newQuantity = 0 index" + index);
        this.localCartItems.splice(index, 1);
      }
      this.$emit('update:cartItems', [...this.localCartItems]);
    }
  
    },
    async submitForm() {
      // Перевірка, чи введена вулиця є в списку
      if (this.deliveryType === 'delivery' && !this.streetOptions.includes(this.street)) {
        this.streetError = true;
        return;
      } else {
        this.streetError = false;
      }
      this.isSubmitting = true;
      let deliveryDayText = '';
      if (this.deliveryDay === 'today') {
        deliveryDayText = 'Сьогодні';
      } else if (this.deliveryDay === 'tomorrow') {
        deliveryDayText = 'Завтра';
      } else if (this.deliveryDay === 'day_after_tomorrow') {
        deliveryDayText = 'Післязавтра';
      }

      const address = this.deliveryType === 'delivery'
        ? `Вул ${this.street}, ${this.house}, під ${this.entrance}, пов ${this.floor}, кв ${this.apartment}`
        : 'самовивіз';

      const formattedItems = this.localCartItems.map(item =>
        `*Артикул:* ${item.article}\n*Назва:* ${item.name}\n*Ціна:* ${item.price} грн\n*Кількість:* ${item.quantity}`
      ).join('\n\n');

      const paymentMethodText = this.paymentMethod === 'card' ? 'карта' : 'готівка';

      const message = `
Нове замовлення:
*Ім'я:* ${this.name}
*Телефон:* ${this.phone}
*Пошта:* ${this.email}
*Адреса:* ${address}
*Ресторан:* ${this.selectedRestaurantId}
*Оплата:* ${paymentMethodText}
*Час доставки:* ${deliveryDayText} ${this.deliveryTime}
*Товари:*
${formattedItems}

*Загальна вага:* ${this.totalWeight} грам
*Загальна вартість:* _${this.totalPrice} грн_`;



      try {
        //const response = await fetch(`https://api.sushitokash.com.ua/test`, {
        //const response = await fetch(`https://api.sushitokash.com.ua/order/create`, {
        const response = await fetch(`https://api.sushitokash.com.ua/order/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ text: message })
        });

         // Check if the response status is 200
            if (response.status === 200) {
                this.orderSubmitted = true;
                this.clearCart();
                try{
                  const data = await response.json();
                  console.log(data);
                
                  this.trackBuyEvent();
                  if (data.paymentUrl && (data.paymentUrl !== null) && (data.paymentUrl !== "null")) {
                    const storedRestaurant = localStorage.getItem('activeRestaurant');
                    
                    if (storedRestaurant) {
                      const activeRestaurant = JSON.parse(storedRestaurant);
                      
                      if (activeRestaurant.onlinePayments) {
                        window.location.href = data.paymentUrl;
                      }
                    } else{
                      window.location.href = data.paymentUrl;
                    }
                  }

                } catch (error) {
                    console.error('Cannot parse json:', error); 
                }
                this.orderSubmitted = true;
                this.clearCart();
            } else {
                console.error(`Unexpected response status: ${response.status}`);
                this.showErrorPopup = true; // Show the error popup
                // Handle non-200 status codes here, such as displaying an error message
            }
        } catch (error) {
            console.error('Error submitting order:', error);
            this.showErrorPopup = true; 
        } finally {
          this.isSubmitting = false;  // Enable the button again after response
        }
    },
    closeCart() {
      this.$emit('close-cart');
    },
    clearCart() {
      this.localCartItems = [];
      this.$emit('clear-cart');
    },
    formatPhoneNumber(event) {
      let value = event.target.value;
      if (!value.startsWith('+38')) {
        value = '+38' + value.replace(/[^0-9]/g, '');
      } else {
        value = '+38' + value.substring(3).replace(/[^0-9]/g, '');
      }
      this.phone = value;
    },
    validatePhoneNumber() {
      const phoneNumber = this.phone.replace(/\D/g, ''); 
      if (phoneNumber.length !== 12) {
        alert('Будь ласка, введіть дійсний номер телефону у форматі +38XXXXXXXXXX');
      }
    },
    trackBuyEvent() {
      // try {
      //     gtag('event', 'purchase', {
      //       'event_category': 'Ecommerce',
      //       'event_label': 'Buy Button',
      //       'value': 1
      //     });
      //   }catch (error) {
      //     // Handle the error
      //     console.error('An error occurred:', error.message);
      //   }
    }
  },
  watch: {
    cartItems: {
      handler(newItems) {
        this.localCartItems = [...newItems];
      },
      deep: true
    },
    street(newStreet) {
      if (this.deliveryType === 'delivery') {
        this.streetError = !this.streetOptions.includes(newStreet);
      }
    },
  }
}
</script>

<style>
.shopping-cart {
  font-family: 'Nunito', sans-serif;
  background-color: #232121;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: white;
  position: relative;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.shopping-cart h2 {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 16px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
}

.close-button {
  position: absolute;
  right: 19px;
  background: none;
  border: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  top: 25px;
  z-index: 1002;
}

.clear-button {
  background-color: #ff6666;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1em;
  cursor: pointer;
}

.clear-button:hover {
  background-color: #ff4d4d;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

.cart-item img {
   width: 120px;
  height: 120px;
  border-radius: 8px;
  object-fit: cover;
  margin-right: 15px;
}

.item-details {
  flex-grow: 1;
  color: white;
}
.item-name {
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
}

.item-code {
  font-size: 0.9em;
  color: #ff9999;
  margin-top: 2px;
}

.item-price {
  font-size: 1.2em;
  color: #88c057;
  font-weight: bold;
  margin-top: 8px;
}

.item-weight {
  color: rgb(220, 217, 217);
  margin: 4px 0;
}

.item-quantity {
  display: flex;
  align-items: center;
  background-color: #88c057;
  border-radius: 20px;
  padding: 4px 8px;
  /* max-width: 80px; */
  position: absolute;
  right: 10px;
  bottom: 10%;
}

.item-quantity button {
  background-color: white;
  color: red;
  border: none;
  border-radius: 50% !important;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  font-weight: bold;
  cursor: pointer;
}

.item-quantity span {
  margin: 0 10px;
  font-size: 1.2em;
  color: black;
}

.remove-button {
  background-color: transparent;
  border: none;
  color: #ff4d4d;
  font-size: 1.5em;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10%;
  transform: translateY(-50%);
}

.remove-button:hover {
  color: #ff0000;
}

.mock-quantity {
  min-width: 100px;
  max-width: 120px;
}

.item-quantity .btn {
  border-radius: 15px 15px 15px 15px;
}

.item-quantity span {
  margin: 0 10px;
  font-size: 1.2em;
}

.cart-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 15px;
  border-radius: 10px;
  width: 99%;
}


.summary-text {
  display: flex;
  flex-direction: column;
}

.total-label {
  color: #ccc;
  margin: 0;
  font-size: 1.3em !important; 
  font-weight: bold;
  text-align: left;
}

.total-price {
  font-size: 1.8em;
  color: #88c057;
  font-weight: bold;
  margin: 5px 0 0;
}

.checkout-button {
  background-color: #88c057;
  color: black;
  border: none;
  border-radius: 30px;
  padding: 10px 25px;
  font-size: 1.1em;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout-button:hover {
  background-color: #6f9d45;
}

.arrow {
  background-color: white;
  color: red;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: 1.2em;
}

/* .cart-summary p {
  margin: 4px 0;
  font-weight: bold;
}

.cart-summary .total-price {
  font-size: 25px;
  color: #ff5c5c;
} */

/* .checkout-button {
  background-color: #88c057;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 1em;
  cursor: pointer;
  width: 40%;
  margin-top: 16px;
} */



.form-section {
  background-color: #333;
  padding: 10px 20px 0px 20px;
  border-radius: 10px;
  color: white;
  max-width: 400px;
  margin: 0 auto;
}
.form-section h3 {
  font-size: 1.3em;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: white;
  text-align: left;
}

.form-group-n {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-group-n label {
  font-size: 1em;
  margin-bottom: 5px;
  color: #ccc;
  text-align: left;
}

.form-group-n input {
  background-color: #555;
  border: none;
  padding: 10px;
  font-size: 1em;
  border-radius: 10px;
  color: white;
  width: 90%;
}
.form-group-n select {
  background-color: #555;
  border: none;
  padding: 10px;
  font-size: 1em;
  border-radius: 10px;
  color: white;
  width: 90%;
}
.form-row {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Space between name and phone input fields */
}

.form-group-n.name-group {
  flex: 1; /* Name field takes up less space */
}

.form-group-n.phone-group {
  flex: 2; /* Phone field takes up more space */
}


.form-group-n input::placeholder {
  color: white;
}

.checkout-button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.checkout-button:hover {
  background-color: #71a543;
}

.form-group {
  margin-bottom: 16px;
  width: 100%; 
  display: flex;
  justify-content: space-between;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.form-group input {
  width: 95%;
  padding: 8px;
  margin-bottom: 8px;
  border: none;
  border-radius: 18px;
  color: white;
  background-color: #555;
}

.form-group input::placeholder {
  color: #ddd;
}
.form-group input.input-error {
  border-color: red;
}

.form-group button {
  flex: 1;
  padding: 8px 16px;
  margin-right: 8px;
  border: none;
  background-color: #e0e0e0;
  color: black;
  border-radius: 10px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #6f9d45;
}

.form-group button.active {
  background-color: #88c057;
}

.suggestions {
  background-color: #88c057;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: -8px;
  margin-bottom: 8px;
  max-height: 100px;
  overflow-y: auto;
}

.suggestions p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
  color: black;
}

.suggestions p:hover {
  background-color: #71a543;
}

.thank-you-message {
  text-align: center;
  font-size: 27px;
  color: white;
}



@media (max-width: 768px) {
  .shopping-cart {
    padding: 12px;
  }

  .shopping-cart h2 {
    font-size: 1.2em;
  }

  .cart-header {
    /* flex-direction: column; */
    align-items: center;
  }

  .clear-button {
    margin-top: 10px;
  }

  .cart-item {
    /* flex-direction: column; */
    align-items: center;
  }

  .cart-item img {
    max-width: 100px;
    margin-bottom: 12px;
  }

  .item-quantity {
    /* flex-direction: row; */
    /* width: 100%; */
    justify-content: space-between;
  }

  .item-quantity span {
    font-size: 1em;
  }

  .checkout-button {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .form-group input {
    width: 95%;
  }

  .form-group button {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .suggestions {
    max-height: 80px;
  }

  .thank-you-message {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .nogrid{
    gap: 2px !important;
  }
  .cart-summary {
    text-align: right;
    margin-top: 16px;
  }

  .form-section {
    padding-left:10px;
  }

  .shopping-cart {
    padding: 14px;
  }

  .shopping-cart h2 {
    font-size: 1em;
  }

  .cart-header {
    /* flex-direction: column; */
    align-items: center;
  }

  .clear-button {
    margin-top: 10px;
  }

  .cart-item {
    /* flex-direction: column; */
    align-items: center;
  }

  .cart-item img {
   
  }

  .item-details {
    margin-left: 0;
  }

  .item-quantity {
    /* width: 100%; */
    justify-content: space-between;
  }

  .item-quantity button {
    width: 20px;
    height: 20px;
  }

  .item-quantity span {
    font-size: 1em;
  }

  .checkout-button {
    padding: 8px 10px;
    font-size: 0.8em;
  }

  .form-group input {
    padding: 6px;
  }

  .form-group button {
    padding: 6px 12px;
    font-size: 0.8em;
  }

  .suggestions {
    max-height: 60px;
  }

  .thank-you-message {
    font-size: 20px;
  }
}

.pickup-address {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #f0f0f0 !important;
  font-size: 14px;
}

.pickup-address a {
  color: #f0f0f0 !important; /* Highlight links with a brighter color */
  text-decoration: none; /* Remove underline from links */
  font-weight: bold; /* Make links bold */
}

.delivery-time-group {
  margin-bottom: 16px;
}

.delivery-time-container {
  display: flex;
  justify-content: space-between;
  gap: 16px; 
  width: 100%;
}

.delivery-time-container .delivery-day,
.delivery-time-container .delivery-time {
  flex: 1;
}

/* .delivery-time-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
  color: #fff;
} */

/* .delivery-time-container select {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #b9cfa6; 
  color: #333; 
  appearance: none;
  cursor: pointer;
}

.delivery-time-container select:focus {
  border-color: #88c057; 
  outline: none; 
} */

.required {
  color: red;
  margin-left: 5px;
}


.checkout-button.disabled {
  background-color: #ccc; /* Grey out the button */
  cursor: not-allowed;    /* Change cursor to indicate it's disabled */
}


.error-message {
  color: red;
  margin-top: 5px;
}

.mtt {
  margin-top: 10px
}
.pdb {
  padding-bottom: 10px;
}

a {
  color: white;
}

.nmrb {
  margin-bottom: 0;
}

.agreement-section {
  margin: 7px;
}

.wdthau {
  width: auto !important;
}

.popup-overlay-regform {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-content-regform {
    background-color: #444;
    color: #fff;
    padding: 30px;
    border-radius: 15px;
    text-align: center;
    max-width: 500px;
}

.popup-content-regform h3 {
    margin-bottom: 20px;
    font-size: 22px;
    color: #fff;
}

.popup-content-regform p {
    font-size: 16px;
    color: #ccc;
}

</style>